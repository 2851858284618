import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header } from '../components/Text.js';

const styles = theme => ({
  iframe: {
    border: "none",
    width: "100%",
    flexGrow: 1,
    minHeight: "400px",
  },
  container: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",

    "& .container div:not(:first-child) .col-12": {
      paddingTop: 64,
      marginTop: 64,
      borderTop: [2, 'solid', theme.color1],
    },
    "& .col-12": {
      marginBottom: 32,
    },
  },
});

function Page(props) {
  let c = props.classes;
  return (
    <div className={c.container}>
      <Header style={{marginBottom: "40px"}}>
        Teams
      </Header>
      <div className='container' id='content'>
        <div className="row">
          <div className="col-12">
            <h2>Austria <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Austria.svg" alt="Flag of Austria" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/531/picture" alt="Picture"/><br/>
            Michael Hollnbuchner<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/532/picture" alt="Picture"/><br/>
            Dorian Jost<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/533/picture" alt="Picture"/><br/>
            Anna Lenz<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/460/picture" alt="Picture"/><br/>
            Walther Janous<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/534/picture" alt="Picture"/><br/>
            Minh Hieu Nguyen<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/535/picture" alt="Picture"/><br/>
            Lubing Ruan<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/536/picture" alt="Picture"/><br/>
            Zsolt Tarka<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/458/picture" alt="Picture"/><br/>
            Birgit Vera Schmidt<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Croatia <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Croatia.svg" alt="Flag of Croatia" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/571/picture" alt="Picture"/><br/>
            Vedran Cifrek<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/572/picture" alt="Picture"/><br/>
            Matej Ljubičić<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/573/picture" alt="Picture"/><br/>
            David Mikulčić<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/462/picture" alt="Picture"/><br/>
            Ivan Kokan<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/574/picture" alt="Picture"/><br/>
            Krešimir Nežmah<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/575/picture" alt="Picture"/><br/>
            Gabrijel Radovčić<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/576/picture" alt="Picture"/><br/>
            Lovro Vladić<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/486/picture" alt="Picture"/><br/>
            Matko Ljulj<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Czech Republic <img height="40" src="/MEMO/static/media/public/flags/Flag_of_the_Czech_Republic.svg" alt="Flag of Czech Republic" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/537/picture" alt="Picture"/><br/>
            Jana Bušová<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/544/picture" alt="Picture"/><br/>
            Vojtěch David<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/539/picture" alt="Picture"/><br/>
            Viktor Fukala<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/481/picture" alt="Picture"/><br/>
            Filip Bialas<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/543/picture" alt="Picture"/><br/>
            Adéla Heroudková<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/541/picture" alt="Picture"/><br/>
            Magdaléna Mišinová<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/542/picture" alt="Picture"/><br/>
            Jan Vavřín<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/483/picture" alt="Picture"/><br/>
            Danil Koževnikov<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Germany <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Germany.svg" alt="Flag of Germany" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/487/picture" alt="Picture"/><br/>
            Maximilian Hauck<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/488/picture" alt="Picture"/><br/>
            Matthias Lotze<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/489/picture" alt="Picture"/><br/>
            Patrick Nasri-Roudsari<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/464/picture" alt="Picture"/><br/>
            Kevin Höllring<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/490/picture" alt="Picture"/><br/>
            Paul Paschmanns<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/494/picture" alt="Picture"/><br/>
            Edgar Perner<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/491/picture" alt="Picture"/><br/>
            Veran Stojanovic<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/564/picture" alt="Picture"/><br/>
            Sabine Gack<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Hungary <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Hungary.svg" alt="Flag of Hungary" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/558/picture" alt="Picture"/><br/>
            Csongor Beke<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/559/picture" alt="Picture"/><br/>
            Péter Gyimesi<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/560/picture" alt="Picture"/><br/>
            Máté Soós<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/466/picture" alt="Picture"/><br/>
            Dániel Lenger<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/561/picture" alt="Picture"/><br/>
            Balázs Tóth<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/562/picture" alt="Picture"/><br/>
            Zsombor Várkonyi<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/563/picture" alt="Picture"/><br/>
            Máté Weisz<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/467/picture" alt="Picture"/><br/>
            József Szoldatics<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Lithuania <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Lithuania.svg" alt="Flag of Lithuania" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/525/picture" alt="Picture"/><br/>
            Modestas Gujis<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/526/picture" alt="Picture"/><br/>
            Gytis Steišūnas<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/527/picture" alt="Picture"/><br/>
            Rokas Urbonas<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/468/picture" alt="Picture"/><br/>
            Romualdas Kašuba<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/528/picture" alt="Picture"/><br/>
            Dovydas Vadišius<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/529/picture" alt="Picture"/><br/>
            Paulius Vijeikis<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/530/picture" alt="Picture"/><br/>
            Eligijus Žemulis<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/469/picture" alt="Picture"/><br/>
            Aivaras Novikas<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Poland <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Poland.svg" alt="Flag of Poland" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/518/picture" alt="Picture"/><br/>
            Adam Dankowiakowski<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/519/picture" alt="Picture"/><br/>
            Jan Dobrakowski<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/520/picture" alt="Picture"/><br/>
            Paweł Gadziński<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/470/picture" alt="Picture"/><br/>
            Dominik Burek<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/521/picture" alt="Picture"/><br/>
            Kosma Kasprzak<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/522/picture" alt="Picture"/><br/>
            Mateusz Nowak<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/523/picture" alt="Picture"/><br/>
            Iwo Pilecki-Silva<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/501/picture" alt="Picture"/><br/>
            Tomasz Cieśla<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Slovakia <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Slovakia.svg" alt="Flag of Slovakia" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/581/picture" alt="Picture"/><br/>
            Viktor Csaplár<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/582/picture" alt="Picture"/><br/>
            Jozef Fülöp<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/583/picture" alt="Picture"/><br/>
            Matej Hanus<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/472/picture" alt="Picture"/><br/>
            Róbert Hajduk<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/584/picture" alt="Picture"/><br/>
            Lucia Krajčoviechová<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/580/picture" alt="Picture"/><br/>
            Norbert Micheľ<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/579/picture" alt="Picture"/><br/>
            Timea Szöllősová<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/578/picture" alt="Picture"/><br/>
            Ákos Záhorský<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Slovenia <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Slovenia.svg" alt="Flag of Slovenia" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/552/picture" alt="Picture"/><br/>
            Nejc Amon<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/553/picture" alt="Picture"/><br/>
            Jan Genc<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/554/picture" alt="Picture"/><br/>
            Juš Kocutar<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/475/picture" alt="Picture"/><br/>
            David Popović<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/555/picture" alt="Picture"/><br/>
            Matija Likar<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/556/picture" alt="Picture"/><br/>
            Urban Vesel<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/557/picture" alt="Picture"/><br/>
            Gal Zmazek<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/484/picture" alt="Picture"/><br/>
            Andraž Maier<br/>
            <i>Deputy leader</i><br/><br/>
            
            
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Switzerland <img height="40" src="/MEMO/static/media/public/flags/Flag_of_Switzerland.svg" alt="Flag of Switzerland" stylenone="border: solid black 1pt"/></h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/509/picture" alt="Picture"/><br/>
            Ema Skottova<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/508/picture" alt="Picture"/><br/>
            Johann Williams<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/510/picture" alt="Picture"/><br/>
            Joël Huber<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/492/picture" alt="Picture"/><br/>
            Cyril Frei<br/>
            <i>Leader</i><br/><br/>
            
            
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/511/picture" alt="Picture"/><br/>
            Julia Sollberger<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/524/picture" alt="Picture"/><br/>
            Yanis Bena<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/551/picture" alt="Picture"/><br/>
            Florian Keta<br/>
            <i>Contestant</i><br/><br/>
            
            
          </div>
          <div className="col-6  col-md-3">
            
            
            <img width="120" src="/MEMO/persons/participant/493/picture" alt="Picture"/><br/>
            Viera Klasovita<br/>
            <i>Deputy leader</i><br/><br/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
