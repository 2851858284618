import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";

export const h1 = ({staticContext, ...props}) => (<h1 {...props}>{props.children}</h1>);
export const h2 = ({staticContext, ...props}) => (<h2 {...props}>{props.children}</h2>);
export const h3 = ({staticContext, ...props}) => (<h3 {...props}>{props.children}</h3>);
export const h4 = ({staticContext, ...props}) => (<h4 {...props}>{props.children}</h4>);
export const h5 = ({staticContext, ...props}) => (<h5 {...props}>{props.children}</h5>);
export const h6 = ({staticContext, ...props}) => (<h6 {...props}>{props.children}</h6>);

export const p = ({className, children}) => (<p className={className}>{children}</p>);

export const Header = ({level=1, children, ...props}) => {
  let Cmp = withStyles(theme => ({
    header: {
      width: level === 1 ? "100%": 'auto',
      textAlign: level === 1 ? "center": 'left',
      marginBottom: [
        64,
        16,
        8,
        2,
        0,
        0,
      ][level-1] + "px",
      color: [
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
      ][level-1],
      fontSize: [
        40,
        24,
        20,
        18,
        18,
        16
      ][level-1] + "px",
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    let CurrentHeader = [h1, h2, h3, h4, h5, h6][Math.min(5, level-1)];
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.header;

    return (
      <CurrentHeader {...props}>
        {children}
      </CurrentHeader>
    );
  }));
  return <Cmp {...props}/>;
};

export const Text = ({level=1, children, shift=1, ...props}) => {
  let Cmp = withStyles(theme => ({
    text: {
      marginLeft: (shift-1)*8+"px",
      fontSize: [
        18,
        16,
        14,
        12
      ][level-1] + "px",
    },
  }))(({classes, ...props}) => {
    let c = classes;
    let CurrentText = p;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.text;

    return (
      <CurrentText {...props}>
        {children}
      </CurrentText>
    );
  });
  return <Cmp {...props}/>;
};

export const MyLink = ({children, ...props}) => {
  let Cmp = withStyles(theme => ({
    link: {
      color: theme.color1,
    },
  }))(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.link;

    if (props.to) {
      return (
        <Link {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <a {...props}>
          {children}
        </a>
      );
    }
  });
  return <Cmp {...props}/>;
}

export const ColumnedText = ({children, ...props}) => {
  let Cmp = withStyles(theme => ({
    cmp: {
      columnCount: 2,
      columnWidth: "300px",
    },
  }))(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.cmp;

    return (
      <div className={props.className}>
        {children}
      </div>
    );
  });
  return <Cmp {...props}/>;
}
