import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text, MyLink, ColumnedText } from '../components/Text.js';
import { List, Item } from '../components/Layout.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Rules
      </Header>

      <ColumnedText>
        <List ordered={true}>
          <Item>
            The individual competition will start approximately at 09:00 a.m. and will last for 5 hours. The test will consist of four problems. The order of the problems will not depend on their difficulty; the first problem will be from algebra, the second from combinatorics, the third from geometry and the fourth from number theory.
          </Item>

          <Item>
            The team competition will start approximately at 09:00 a.m. and will last for 5 hours. The students from each team will work together on solving the problems. Each team will have a separate room.
            The test will consist of eight problems. The order of the problems will not depend on their difficulty; the first two problems will be from algebra, the third and fourth from combinatorics, the fifth and sixth from geometry and the last two from number theory.
          </Item>

          <Item>
            Each contestant will receive the problems in up to two languages; the desired languages can be requested on the registration form. Each contestant may write solutions in the language of his/her choice, provided that the leader or the deputy leader of his/her team can translate from that language. The solutions may only be written on the front side of the writing paper provided by the organizers.
            Contestants may pose questions to the Jury in written form during the first hour of each competition.
          </Item>

          <Item>
            The only instruments permitted in the competition will be writing and drawing instruments, such as rulers and compasses. In particular, books, papers, tables, calculators, computers and communication devices will not be allowed into the examination room. 
          </Item>

          <Item>
            Each problem in both competitions is worth 8 points. The marks will be agreed between the coordinators and the leader and deputy leader, according to the marking schemes. The coordination of the solutions of the host team will take place in the presence of the leader of one of the other teams. In case of dispute, the final decision is on the Jury. 
          </Item>

          <Item>
            Approximately half of the contestants will receive a medal (gold, silver or bronze) for the individual competition. The number of gold, silver and bronze medals will be approximately in the ratio 1:2:3. The Jury will make the final decision on the number of medals taking into account the distribution of the points of the contestants. Contestants with the same number of points will be treated equally. Also, each contestant not receiving a medal who has a full score (8 points) on at least one problem will receive an Honourable Mention. 
          </Item>

          <Item>
            The members of the teams that come in first, second and third place in the team competition will receive gold, silver and bronze medals, respectively. In case of a tie for one of the first three positions, the following tie breaker will be applied: For each team involved in the tie, we sort the awarded points of each of the 8 problems in descending order; then we read it as an eight-digit integer. A team is better if the corresponding number is greater. If these numbers are equal, then the teams will share the position. In case of a tie for a position other than the first three, the tie will not be broken. 
          </Item>
        </List>
      </ColumnedText>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
