import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, MyLink, Text } from '../components/Text.js';
import { Paddinger } from '../components/Layout.js';

import RI from '../data/files/results/results_individual.pdf'
import RT from '../data/files/results/results_teams.pdf'

const styles = theme => ({
});

function Page(props) {
  const c = props.classes;
  //  <MyLink href="https://www.memo-official.org/MEMO/results/2019/team/?plain=True">
  //  <MyLink href="https://www.memo-official.org/MEMO/results/2019/individual/?plain=True">
  return (
    <div>
      <Header>
        Results
      </Header>
      
      <Text level={2}>
        <MyLink href={RT}>
          Team Results
        </MyLink>
      </Text>

      <Text level={2}>
        <MyLink href={RI}>
          Individual Results
        </MyLink>
      </Text>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
