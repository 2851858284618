import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text, MyLink } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

import GeneralRegulationsPDF from '../data/files/GeneralRegulations.pdf';
import AnnualRegulationsPDF from '../data/files/AnnualRegulations.pdf';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Regulations
      </Header>
      <Text>
        <MyLink href={GeneralRegulationsPDF}>
          General Regulations
        </MyLink>
      </Text>

      <Text>
        <MyLink href={AnnualRegulationsPDF}>
          Annual Regulations
        </MyLink>
      </Text>
    {/*
      <Header level={2}>
        1 General
      </Header>
      <Paddinger>
        <Text>
          1.1. The Middle European Mathematical Olympiad (MEMO) is governed by these General Regulations together with the Annual Regulations which specify details specific to each MEMO or required by national law. The Annual Regulations take precedence over the General Regulations.
        </Text>

        <Text>
          1.2. The Jury of the MEMO consists of the Leaders and Deputy Leaders of the participating countries, as well as the Chair of the Jury.
        </Text>

        <Text>
          1.3. Each country has one vote in Jury votings. Ties are resolved by the Chair of the Jury.
        </Text>

        <Text>
          1.4. If a Jury wants to change a vote made by the Jury of the same MEMO, it can only be done by at least a two-thirds majority of eligible votes.
        </Text>

        <Text>
          1.5. These General Regulations can be modified by a Jury voting with a simple majority of the permanently participating countries, and the changes are valid beginning with the next MEMO.
        </Text>

        <Text>
          1.6. If changes to the General Regulations should be effective immediately, this has to be decided by at least a two-thirds majority of the eligible votes of the Jury and has to be approved by the host country.
        </Text>

        <Text>
          1.7. The Jury can demand a voting to be postponed to the consecutive year so that they can consult with their national committees.
        </Text>

        <Text>
          1.8. A meeting of the Jury has to take place at the next possible time when at least three Leaders or the Chair of the Jury request it.
        </Text>
      </Paddinger>

      <Header level={2}>
        2  Hosting
      </Header>
      <Paddinger>
        <Text>
          2.1. The MEMO takes place each year in August or September.
        </Text>

        <Text>
          2.2. The MEMO lasts for 7 days (6 nights).
        </Text>

        <Text>
          2.3. The hosting of the MEMO rotates among all participating countries.
        </Text>

        <Text>
          2.4. The host country will cover all official expenses for the delegations from all participating countries, including meals, accommodation and local transport during the event.
        </Text>

        <Text>
          2.5. Travel costs to the location of the competition will be at the expense of each participating country.
        </Text>

        <Text>
          2.6. International delegations participating in the MEMO are responsible for obtaining full accident, health and travel insurance for their team members.
        </Text>

        <Text>
          2.7. The participation fee for each participating country is set by the host country to an amount of at most 1200 EUR.
        </Text>
      </Paddinger>

      <Header level={2}>
        3 Participation
      </Header>
      <Paddinger>
        <Text>
          3.1. Participation in the MEMO is by invitation only.
        </Text>

        <Text>
          3.2. The following countries are to be invited: Austria, Croatia, Czech Republic, Germany, Hungary, Lithuania, Poland, Slovakia, Slovenia, Switzerland.
        </Text>

        <Text>
          3.3. The host country may invite one guest country. This country may fully participate in one MEMO, but may not vote on changes of these General Regulations.
        </Text>

        <Text>
          3.4. Each invited country may send an official delegation consisting of up to six student Contestants, one Leader, and one Deputy Leader.
        </Text>

        <Text>
          3.5. The Contestants must not participate in the IMO of the same year, and they must be eligible for participation in the IMO of the subsequent year.
        </Text>

        <Text>
          3.6. Exception: Slovenia is entitled to send Contestants to MEMO who participated in the IMO of the same year as long as all other requirements listed above are met.
        </Text>

        <Text>
          3.7. Observers, including family members, may apply to accompany the participants. The Annual Regulations specify the charges for Observers and the deadlines for payment of such charges. Since extra accommodation is limited, no guarantee is given that applications to accompany the participants will be successful.
        </Text>
      </Paddinger>

      <Header level={2}>
        4 Problem Selection
      </Header>
      <Paddinger>
        <Text>
          4.1. All participating countries are kindly asked to submit problem proposals to the host country.
        </Text>

        <Text>
          4.2. The host country will select a shortlist out of these proposals covering all four topics of algebra, combinatorics, geometry and number theory.
        </Text>

        <Text>
          4.3. The shortlist will be sent to the Leaders and Deputy Leaders of the participating countries at least 3 weeks prior to the start of the competition.
        </Text>

        <Text>
          4.4. Each participating country is requested to provide beauty ratings (on a scale from 1 to 3 where 3 is most beautiful) and difficulty ratings (on a scale from 1 to 5 where 5 is most difficult) for each problem.
        </Text>

        <Text>
          4.5. The results of the beauty and difficulty ratings will be presented to the Jury at the beginning of the first Jury meeting.
        </Text>

        <Text>
          4.6. The Jury will select an individual competition and a team competition from the problems on the shortlist.
        </Text>

        <Text>
          4.7. Individual competition: The test will consist of four problems. The order of the problems will not depend on their difficulty; the first problem will be from algebra, the second from combinatorics, the third from geometry and the fourth from number theory.
        </Text>

        <Text>
          4.8. Team competition: The test will consist of eight problems. The order of the problems will not depend on their difficulty; the first two problems will be from algebra, the third and fourth from combinatorics, the fifth and sixth from geometry and the last two from number theory.
        </Text>

        <Text>
          4.9. The Jury will agree on a final version of the English language version of both competitions.
        </Text>

        <Text>
          4.10. Both competitions will be translated to all languages requested by the Contestants. Contestants may only request languages which one of the (Deputy) Leaders can translate to.
        </Text>

        <Text>
          4.11. The final versions of all languages must be approved by the entire Jury.
        </Text>

        <Text>
          4.12. All submitted problems (including shortlisted problems) which were not selected must be kept strictly confidential forever so that the submitters can freely use them elsewhere.
        </Text>

        <Text>
          4.13. The selected problems have to be kept strictly confidential until the end of the respective competition.
        </Text>
      </Paddinger>

      <Header level={2}>
        5 Competitions
      </Header>
      <Paddinger>
        <Text>
          5.1. Each Contestant will receive the problems in up to two languages; the desired languages can be requested on the registration form.
        </Text>

        <Text>
          5.2. Each Contestant may write solutions in the language of his/her choice, provided that the Leader or the Deputy Leader of his/her team can translate that language to English. If two teams’ (Deputy) Leaders agree before the competition, then students of one Leader are allowed to write in the language of the other (Deputy) Leader.
        </Text>

        <Text>
          5.3. Contestants may pose questions to the Jury in written form during the first hour of each competition.
        </Text>

        <Text>
          5.4. The solutions may only be written on the front side of the writing paper provided by the organizers.
        </Text>

        <Text>
          5.5. Allowed are:
        </Text>
        <Paddinger>
          <List>
            <Item>
              Writing and drawing instruments,
            </Item>
            <Item>
              Rulers,
            </Item>
            <Item>
              Protractor triangles,
            </Item>
            <Item>
              Compasses,
            </Item>
            <Item>
              Food and drinks,
            </Item>
            <Item>
              Talismans,
            </Item>
            <Item>
              Medication, glasses, hearing aids, ...
            </Item>
          </List>
        </Paddinger>
        <Text>
          5.6. Forbidden are:
        </Text>
        <Paddinger>
          <List>
            <Item>
              Any kind of paper (official paper will be provided),
            </Item>
            <Item>
              Calculators,
            </Item>
            <Item>
              Computers,
            </Item>
            <Item>
              Communication devices (including smart phones and smart watches).
            </Item>
          </List>
        </Paddinger>
        <Text>
          5.7. At all times during the competition an invigilator has to be present to prevent communication between students or usage of forbidden tools.
        </Text>

        <Header level={3}>
          5.1 Individual Competition
        </Header>
        <Paddinger>
          <Text>
            5.8. The individual competition will start in the morning and will last for 5 hours.
          </Text>

          <Text>
            5.9. Each Contestant must work independently.
          </Text>
        </Paddinger>

        <Header level={3}>
          5.2 Team Competition
        </Header>
        <Paddinger>
          <Text>
            5.10. The team competition will start in the morning and will last for 5 hours.
          </Text>

          <Text>
            5.11. The students from each team will work together on solving the problems.
          </Text>

          <Text>
            5.12. Each team will have a separate room.
          </Text>
        </Paddinger>
      </Paddinger>

      <Header level={2}>
        6 Scoring
      </Header>
      <Paddinger>
        <Header level={3}>
          6.1 Marking Schemes
        </Header>
        <Paddinger>
          <Text>
            6.1. The Jury will agree (by voting) on marking schemes for all problems.
          </Text>

          <Text>
            6.2. The marking schemes should be agreed on before the end of the corresponding competition.
          </Text>

          <Text>
            6.3. Changing a marking scheme after the end of the competition requires an unanimous vote by the Jury.
          </Text>
        </Paddinger>

        <Header level={3}>
          6.2 Coordination
        </Header>
        <Paddinger>
          <Text>
            6.4. The student papers will be copied or scanned. Coordinators will receive copies or scans of the student papers, and the Leaders will receive the originals.
          </Text>

          <Text>
            6.5. For each problem, each Contestant (individual competition) or team (team competition) receives a non-negative integer score out of a maximum of 8 points.
          </Text>

          <Text>
            6.6. The marks will be agreed on between the Coordinators and the Leader and Deputy Leader, according to the marking schemes. If another team’s (Deputy) Leader has agreed to translate a student’s solution, then this team’s (Deputy) Leader is present during coordination of problems where this language has been used.
          </Text>

          <Text>
            6.7. Changing a score agreed upon by Coordinators and Leaders (by signature on the coordination form) requires an at least two-thirds majority of the Jury.
          </Text>

          <Text>
            6.8. The coordination of the solutions of the host team will take place in the presence of the Leader of one of the other teams, usually of the team that proposed the problem. If the problem was proposed by the host country itself, an observing country will be chosen at random.
          </Text>

          <Text>
            6.9. In case of dispute, the matter is referred to the Chair of the Jury. If there is still no agreement, the final decision is on the Jury.
          </Text>
        </Paddinger>

        <Header level={3}>
          6.3 Medals and Honourable Mentions
        </Header>
        <Paddinger>
          <Header level={4}>
            6.3.1 Individual Competition
          </Header>
          <Paddinger>
            <Text>
              6.10. Approximately half of the Contestants will receive a medal (gold, silver or bronze) for the individual competition.
            </Text>

            <Text>
              6.11. The number of gold, silver and bronze medals will be approximately in the ratio 1:2:3.
            </Text>

            <Text>
              6.12. The Jury will make the final decision on the number of medals taking into account the distribution of the points of the Contestants.
            </Text>

            <Text>
              6.13. Contestants with the same number of points will be treated equally.
            </Text>

            <Text>
              6.14. Each Contestant not receiving a medal who has a full score (8 points) on at least one problem will receive an Honourable Mention.
            </Text>
          </Paddinger>

          <Header level={4}>
            6.3.2 Team Competition
          </Header>
          <Paddinger>
            <Text>
              6.15. The members of the teams that come in first, second and third place in the team competition will receive gold, silver and bronze medals, respectively.
            </Text>

            <Text>
              6.16. In case of a tie for one of the first three positions, the following tie breaker will be applied: For each team involved in the tie, we sort the awarded points of each of the 8 problems in descending order; then we read it as an eight-digit integer. A team is better if the corresponding number is greater. If these numbers are equal, then the teams will share the position.
            </Text>

            <Text>
              6.17. In case of a tie for a position other than the first three, the tie will not be broken.
            </Text>
          </Paddinger>
        </Paddinger>
      </Paddinger>
      <Text className='float-right'>
        Approved by the Jury of MEMO 2016, 26 August 2016
      </Text>
      */}
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
