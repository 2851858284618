import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text } from '../components/Text.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Empty
      </Header>
      <Text className={"text-center"}>
        There is no info yet.
      </Text>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
