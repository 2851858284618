import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text, ColumnedText } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Schedule
      </Header>
    
      <Header level={3}>
        Monday, the 26th August, 
      </Header>
      <List>
        <Item level={2}>
          arrival of the teams directly to the hotel 
        </Item>
        <Item level={2}>
          rooms are available from 2 p.m. 
        </Item>
        <Item level={2}>
          buffet dinner 6 - 9 p.m. 
        </Item>
      </List>

      <Header level={3}>
        Tuesday, the 27th August
      </Header>
      <List>
        <Item level={2}>
          breakfast for everybody
        </Item>
        <Item level={2}>
          8:40 departure (walking) from the reception towards the university
        </Item>
        <Item level={2}>
          9:00 opening ceremony at the university (nám. čs. legií)
        </Item>
        <Item level={2}>
          9:45 excursion for the contestnants, all-day work for the Jury at the university
        </Item>
        <Item level={2}>
          18:30 everybody meets for the dinner at the hotel
        </Item>
      </List>

      <Header level={3}>
        Wednesday, the 28th August
      </Header>
      <List>
        <Item level={2}>
          breakfast for everybody
        </Item>
        <Item level={2}>
          8:15 departure (walking) from the reception towards the high school
        </Item>
        <Item level={2}>
          9:00-14:00 individual competition, then lunch and sightseeing for the students 
        </Item>
        <Item level={2}>
          Jury after answering questions moves back to the university for some more work
        </Item>
        <Item level={2}>
          18:30 everybody meets for the dinner at the hotel
        </Item>
      </List>
    
      <Header level={3}>
        Thursday, the 29th August
      </Header>
      <List>
        <Item level={2}>
          breakfast for everybody
        </Item>
        <Item level={2}>
          8:15 departure (walking) from the hotel reception towards the high school
        </Item>
        <Item level={2}>
          9:00-14:00 team competition, then lunch and sightseeing for the students 
        </Item>
        <Item level={2}>
          Jury after answering questions moves back to the university for more work
        </Item>
        <Item level={2}>
          18:30 everybody meets for the dinner at the hotel
        </Item>
      </List>

      <Header level={3}>
        Friday, the 30th August
      </Header>
      <List>
        <Item level={2}>
          breakfast for everybody
        </Item>
        <Item level={2}>
          9:00 excursion for the contestnants, all-day work for the Jury at the university
        </Item>
        <Item level={2}>
          18:30 everybody meets for the dinner at the hotel
        </Item>
      </List>

      <Header level={3}>
        Saturday, the 31th August
      </Header>
      <List>
        <Item level={2}>
          breakfast for everybody
        </Item>
        <Item level={2}>
          8:30 excursion for everybody (historical town of Kutna Hora)
        </Item>
        <Item level={2}>
          18:00 price-giving and closing ceremony at the university (nám. čs. legií), buffet dinner at the hotel
        </Item>
      </List>
     
      <Header level={3}>
        Sunday, the 1st September
      </Header>
      <List>
        <Item level={2}>
          departures
        </Item>
      </List>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
