import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, MyLink, Text } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

import Ics from '../data/files/problems/i_cs.pdf'
import Ide from '../data/files/problems/i_de.pdf'
import Ien from '../data/files/problems/i_en.pdf'
import Ifr from '../data/files/problems/i_fr.pdf'
import Ihr from '../data/files/problems/i_hr.pdf'
import Ihu from '../data/files/problems/i_hu.pdf'
import Ilt from '../data/files/problems/i_lt.pdf'
import Ipl from '../data/files/problems/i_pl.pdf'
import Isk from '../data/files/problems/i_sk.pdf'
import Isl from '../data/files/problems/i_sl.pdf'

import Tcs from '../data/files/problems/t_cs.pdf'
import Tde from '../data/files/problems/t_de.pdf'
import Ten from '../data/files/problems/t_en.pdf'
import Tfr from '../data/files/problems/t_fr.pdf'
import Thr from '../data/files/problems/t_hr.pdf'
import Thu from '../data/files/problems/t_hu.pdf'
import Tlt from '../data/files/problems/t_lt.pdf'
import Tpl from '../data/files/problems/t_pl.pdf'
import Tsk from '../data/files/problems/t_sk.pdf'
import Tsl from '../data/files/problems/t_sl.pdf'

const styles = theme => ({
});

function Page(props) {
  const c = props.classes;
  return (
    <div>
      <Header>
        Problems
      </Header>
      
      <Paddinger>
        <Header level={3}>
          Team Competition
        </Header>

        <List level={2}>
          <Item> <MyLink href={Thr}> Croatian </MyLink> </Item>
          <Item> <MyLink href={Tcs}> Czech </MyLink> </Item>
          <Item> <MyLink href={Ten}> English </MyLink> </Item>
          <Item> <MyLink href={Tfr}> French </MyLink> </Item>
          <Item> <MyLink href={Tde}> German </MyLink> </Item>
          <Item> <MyLink href={Thu}> Hungarian </MyLink> </Item>
          <Item> <MyLink href={Tlt}> Lithuanian </MyLink> </Item>
          <Item> <MyLink href={Tpl}> Polish </MyLink> </Item>
          <Item> <MyLink href={Tsk}> Slovak </MyLink> </Item>
          <Item> <MyLink href={Tsl}> Slovene </MyLink> </Item>
        </List>
      </Paddinger>

      <Paddinger>
        <Header level={3}>
          Individual Competition
        </Header>

        <List level={2}>
          <Item> <MyLink href={Ihr}> Croatian </MyLink> </Item>
          <Item> <MyLink href={Ics}> Czech </MyLink> </Item>
          <Item> <MyLink href={Ien}> English </MyLink> </Item>
          <Item> <MyLink href={Ifr}> French </MyLink> </Item>
          <Item> <MyLink href={Ide}> German </MyLink> </Item>
          <Item> <MyLink href={Ihu}> Hungarian </MyLink> </Item>
          <Item> <MyLink href={Ilt}> Lithuanian </MyLink> </Item>
          <Item> <MyLink href={Ipl}> Polish </MyLink> </Item>
          <Item> <MyLink href={Isk}> Slovak </MyLink> </Item>
          <Item> <MyLink href={Isl}> Slovene </MyLink> </Item>
        </List>
      </Paddinger>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
