import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, MyLink } from '../components/Text.js';
import { List, Item } from '../components/Layout.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Previous MEMOs
      </Header>
      <List>
        <Item>
          <MyLink href="http://www.memo2018.abel.bielsko.pl/">
            2018 Bielsko-Biała, Poland
          </MyLink>
        </Item>

        <Item>
          <MyLink href="http://memo2017.lmnsc.lt/">
            2017 Vilnius, Lithuania
          </MyLink>
        </Item>

        <Item>
          <MyLink href="https://www.math.aau.at/MEMO2016/">
            2016 Vöcklabruck, Austria
          </MyLink>
        </Item>

        <Item>
          <MyLink href="http://memo2015.dmfa.si/">
            2015 Koper, Slovenia
          </MyLink>
        </Item>

        <Item>
          2014 Dresden, Germany
        </Item>

        <Item>
          <MyLink href="http://memo2013.mik.uni-pannon.hu/">
            2013 Veszprém, Hungary
          </MyLink>
        </Item>

        <Item>
          2012 Solothurn, Switzerland
        </Item>

        <Item>
          <MyLink href="http://memo2011.math.hr/">
            2011 Varaždin, Croatia
          </MyLink>
        </Item>

        <Item>
          <MyLink href="http://memo2010.skmo.sk/">
            2010 Strečno, Slovakia
          </MyLink>
        </Item>

        <Item>
          <MyLink href="http://www.memo2009.wmi.amu.edu.pl/">
            2009 Poznan, Poland
          </MyLink>
        </Item>

        <Item>
          <MyLink href="http://kag.upol.cz/memo/">
            2008 Olomouc, Czech Republic
          </MyLink>
        </Item>

        <Item>
          2007 Eisenstadt, Austria
        </Item>
      </List>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
