import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text, ColumnedText, MyLink } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

import GeneralRegulationsPDF from '../data/files/GeneralRegulations.pdf';

const styles = theme => ({
  mapContainer: {
    width: "100%",
    minHeight: "400px",
    "& > div" :{
      width: "100%",
      paddingTop: "56.25%",
      marginTop: "20px",
      position: "relative",
    },
  },
  map: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minHeight: "400px",
    border: "none",
  }
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Local info
      </Header>
      <ColumnedText>
        <Text>
          The 13th MEMO will is held in Parubice in East Bohemia region.
          Pardubice is a major railway knot and very well connected by the train to many cities.
          In Particular there are several trains every hour from Prague and the trip takes only 50 minutes.
          The hotel is 10&nbsp;-&nbsp;15&nbsp;minutes walking from the train station.
          It is probably not necessary to take a bus as it is just one and half stops.
          You will be situated in the center of the town and all the facilities used for MEMO will be close.
          It will a WALKING MEMO this year, no buses except for excursions.
        </Text>
        <Text>
          The arrival day is Monday, the 26th August.
          We will be waiting for you at the reception of Hotel Labe (<MyLink href="http://www.hotellabe.cz">https://www.hotellabe.cz/</MyLink>), rooms are available from 2&nbsp;p.m. and there will be a buffet dinner 6&nbsp;-&nbsp;9&nbsp;p.m.
          You will find more information about the schedule <MyLink to={"/schedule"}>here</MyLink>.
        </Text>
      </ColumnedText>
      <div className={c.mapContainer}>
        <div>
          <iframe className={c.map} src="https://www.google.com/maps/d/embed?mid=1AaMt9wiwJgjiuOlQpLrYRLBtHJ25YXsH"></iframe>
        </div>
      </div>
   </div>
  );
}

export default withStyles(styles)(withRouter(Page));
