import React, { Component } from 'react';
import Main from './pages/Main.js';
import { HashRouter as Router, Route, Link } from "react-router-dom";
import { ThemeProvider } from 'theming';
import ReactBreakpoints from 'react-breakpoints'
import { create as createJss} from 'jss';
import preset from 'jss-preset-default';
import { JssProvider } from 'react-jss';
import vendorPrefixer from 'jss-vendor-prefixer';

const jss = createJss();
jss.setup(preset());
//jss.use(vendorPrefixer());
 
const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}
 
const theme = {
  color1: "#58c9d9",
  color2: "#b94f9d",
  fontFamily: "'PT Serif Caption', serif",
}

const App = (props) => (
  <ReactBreakpoints breakpoints={breakpoints}>
    <JssProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <Router>
          <Main/>
        </Router>
      </ThemeProvider>
    </JssProvider>
  </ReactBreakpoints>
);

export default App;
