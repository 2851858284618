import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, Text, MyLink } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

import Logo from '../data/images/public_logo.png';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Logotypes
      </Header>
      <Text>
        <MyLink href={Logo}>
          <img width={"200px"} src={Logo}/>
        </MyLink>
      </Text>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
