import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header } from '../components/Text.js';
import { Text, ColumnedText } from '../components/Text.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        About MEMO
      </Header>
      <ColumnedText>
        <Text level={2}>
          The Middle European Mathematical Olympiad (MEMO) is an annual mathematical competition that was first held in 2007. It is the successor of Austrian-Polish Mathematical Competition (ÖPMW / APZM), which was held 29 times from 1978 to 2006 as a competition between one Austrian and one Polish team. Now ten countries participate in the MEMO: Austria, Croatia, Czech Republic, Germany, Hungary, Lithuania, Poland, Slovakia, Slovenia and Switzerland.
        </Text>

        <Text level={2}>
          Similar to the International Mathematical Olympiad (IMO), each country participates with up to six students and two team leaders. As one of the main goals of the new competition was to provide the possibility for a larger number of students to gain experience in international competitions, the teams participating in the MEMO are typically disjoint from the IMO teams of their countries. Also, MEMO is meant to prepare younger students for a future participation in the International Mathematical Olympiad, therefore students in their senior year are not eligible to be in the MEMO team.
        </Text>

        <Text level={2}>
          The MEMO consist of an individual competition and a team competition, each lasting five hours. The problems are of the “olympic” type, typically divided into four areas: algebra, combinatorics, geometry and number theory. In the individual competition, the students solve one problem from each area. The team competition consists of eight problems, two from each area (although at the first two MEMOs also the team competition had only four problems). In the team competition, the students of each team solve the problems together, which gives the competition a very special atmosphere. The team part of the competition existed also at the ÖPMW / APZM, but it is otherwise a rarity in mathematical competitions.
        </Text>

        <Text level={2}>
          The whole program lasts for one week. Problem selection, translation, the competition itself, and the scoring are all performed during this time. Besides the individual and team competition, the program also contains excursions and sports activities.
        </Text>
      </ColumnedText>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
