import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header } from '../components/Text.js';
import { Text, ColumnedText, MyLink } from '../components/Text.js';

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  return (
    <div>
      <Header>
        Welcome to MEMO 2019
      </Header>

      
      <ColumnedText>
        <Text>
          The 13th Middle European Mathematical Olympiad is held in Pardubice, Czech Republic, from August 26 till September 1, 2019. There are 60 contestants from 10 countries: Austria, Croatia, Czech Republic, Germany, Hungary, Lithuania, Poland, Slovakia, Slovenia and Switzerland. 
        </Text>
        <Text>
          The main organizer of the competition is <MyLink href={"https://www.jcmf.cz/?q=en/"}>The Union of Czech Mathematicians and Physicists</MyLink> and it is helped by three co-organizers <MyLink href={"https://www.gypce.cz/"}>Gymnázium Pardubice</MyLink>, <MyLink href={"https://fei.upce.cz/en"}>Faculty of Electrical Engineering and Informatics of University Pardubice</MyLink> and <MyLink href={"https://www.mff.cuni.cz/en"}>Faculty of Mathematics and Physics Charles University</MyLink>.
        </Text>

        <Text>
          This event is held under the auspices of the Governor of the <MyLink href={"https://www.pardubickykraj.cz/the-pardubice-region"}>Pardubice Region</MyLink> Martin Netolický. It received a financial support from the <MyLink href={"http://www.msmt.cz/?lang=2"}>Ministry of Education, Youth and Sports</MyLink>, the <MyLink href={"https://www.rsj.com/en/foundation/about-us"}>RSJ foundation</MyLink> and the <MyLink href={"https://www.unicorncollege.cz/en/"}>Unicorn College</MyLink>.
        </Text>
      </ColumnedText>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
