import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header } from '../components/Text.js';

const roles = [
  'Organizers',
  'Coordinators (Combinatorics)',
  'Coordinators (Algebra)',
  'Coordinators (Geometry)',
  'Coordinators (Number Theory)',
  'Chair of the Jury',
  'Main Organizer',
]

const data = [
  {
    code: "485",
    name: "Tomáš Bárta",
    role: 1
  },
  {
    code: "495",
    name: "Pavel Calábek",
    role: 2
  },
  {
    code: "666",
    name: "Zdena Crkalová",
    role: 0
  },
  {
    code: "503",
    name: "David Hruška",
    role: 1
  },
  {
    code: "512",
    name: "Pavel Hudec",
    role: 4
  },
  {
    code: "516",
    name: "Jan Kratochvíl",
    role: 5
  },
  {
    code: "505",
    name: "Jakub Löwit",
    role: 1
  },
  {
    code: "514",
    name: "Mirek Olšák",
    role: 2
  },
  {
    code: "513",
    name: "Martin Raška",
    role: 3
  },
  {
    code: "517",
    name: "Pavel Šalom",
    role: 2
  },
  {
    code: "497",
    name: "Jaromír Šimša",
    role: 4
  },
  {
    code: "459",
    name: "Zbyněk Šír",
    role: 6
  },
  {
    code: "504",
    name: "Radovan Švarc",
    role: 3
  },
  {
    code: "496",
    name: "Jaroslav Švrček",
    role: 3
  },
  {
    code: "506",
    name: "Pavel Turek",
    role: 4
  },
  {
    code: "667",
    name: "Lukáš Čegan",
    role: 0
  },
  {
    code: "668",
    name: "Magdaléna Křišťanová",
    role: 0
  },
  {
    code: "669",
    name: "Soňa Křišťanová",
    role: 0
  },
  {
    code: "670",
    name: "Josef Kubát",
    role: 0
  },
]

const c = new Intl.Collator();

data.sort((a, b) => {
  a = a.name.split(" ")[1]
  b = b.name.split(" ")[1]
  return c.compare(a, b)
})

console.log(data)

const styles = theme => ({
  iframe: {
    border: "none",
    width: "100%",
    flexGrow: 1,
    minHeight: "400px",
  },
  container: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

const Profile = withStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-begin',

    "& > img": {
      width: 120,
    },
    "& > span": {
      marginTop: 12,
      whiteSpace: 'nowrap',
    },
  }
}))(props => {
  const c = props.classes

  return (
    <div className={c.container}>
      <img src={`/MEMO/persons/participant/${props.code}/picture`}/>
      <span>{ props.name }</span>
    </div>
  )
})

const Section = withStyles(theme => ({
  section: {
    "&:not(:last-child)": {
      marginBottom: 64,
    },
  },
  content: {
    marginTop: 32,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 120px)',
    gridGap: '64px 63px',
    justifyContent: 'space-evenly',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    "& > div": {
      flexGrow: 1,
      borderBottom: [2, 'solid', theme.color1],
    },
    "& > span": {
      width: 24,
    },
  },
}))(props => {
  const c = props.classes

  return (
    <div className={c.section}>
      <div className={c.heading}>
        <div/>
        <span/>
        <Header level={3}>{ props.heading }</Header>
        <span/>
        <div/>
      </div>
      <div className={c.content}>
        {
          props.data.map((p, i) => <Profile key={i} {...p}/>)
        }
      </div>
    </div>
  )
})

function Page(props) {
  let c = props.classes;
  return (
    <div className={c.container}>
      <Header style={{marginBottom: "40px"}}>
        Staff
      </Header>
      <div>
        <Section heading={roles[5]} data={data.filter(p => p.role===5)}/>
        <Section heading={roles[6]} data={data.filter(p => p.role===6)}/>
        <Section heading={roles[0]} data={data.filter(p => p.role===0)}/> 
        <Section heading={roles[1]} data={data.filter(p => p.role===1)}/>
        <Section heading={roles[2]} data={data.filter(p => p.role===2)}/>
        <Section heading={roles[3]} data={data.filter(p => p.role===3)}/>
        <Section heading={roles[4]} data={data.filter(p => p.role===4)}/>
      </div>
    </div>
  )
}

export default withStyles(styles)(withRouter(Page));
