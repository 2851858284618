import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";

export const Ul = ({className, children}) => (<ul className={className}>{children}</ul>);
export const Ol = ({className, children}) => (<ol className={className}>{children}</ol>);
export const Li = ({className, children}) => (<li className={className}>{children}</li>);
export const Div = ({className, children}) => (<div className={className}>{children}</div>);

export const Paddinger = ({level=1, children, ...props}) => {
  let Cmp = withStyles(theme => ({
    paddinger: {
      marginLeft: (level*16)+"px",
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.paddinger;

    return (
      <Div {...props}>
        {children}
      </Div>
    );
  }));
  return <Cmp {...props}/>;
};

export const List = ({level=1, children, ordered=false, ...props}) => {
  let Cmp = withStyles(theme => ({
    list: {
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.list;

    if (!ordered) {
      return (
        <Ul {...props}>
          {children}
        </Ul>
      );
    } else {
      return (
        <Ol {...props}>
          {children}
        </Ol>
      );
    }
  }));
  return <Cmp {...props}/>;
};

export const Item = ({level=1, children, ...props}) => {
  let Cmp = withStyles(theme => ({
    item: {
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.item;

    return (
      <Li {...props}>
        {children}
      </Li>
    );
  }));
  return <Cmp {...props}/>;
};

