import React, { useState } from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import useCollapse from 'react-collapsed'
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, MyLink } from '../components/Text.js';
import { Paddinger } from '../components/Layout.js';
import { Gallery, GridGallery } from '../components/Images.js';

import "react-image-gallery/styles/css/image-gallery.css";


function image(orig_url, thumbnail_url) {
  return {
    original: orig_url,
    src: orig_url,
    thumbnail: thumbnail_url || orig_url,
  };
}

function importImages(r) {
  let thumbnails = {}
  let images = {}
  r.keys().forEach((item, index) => {
    const src = r(item)

    if (src.includes('.image.')) {
      images[src.substring(0, src.indexOf('.image.'))] = src
    } else if (src.includes('.thumbnail.')) {
      thumbnails[src.substring(0, src.indexOf('.thumbnail.'))] = src
    }
  })

  return Object.keys(images).map(k => image(images[k], thumbnails[k]))
}

const galleries = {
  "Perner Gallery": importImages(require.context('../data/galleries/Perner', false, /\.(image|thumbnail)\.png$/)),

  "Opening ceremony": importImages(require.context('../data/galleries/Opening ceremony', false, /\.(image|thumbnail)\.png$/)),

  "Jury": importImages(require.context('../data/galleries/Leaders', false,  /\.(image|thumbnail)\.png$/)),

  "Leaders": importImages(require.context('../data/galleries/Leaders', false, /\.(image|thumbnail)\.png$/)),

  "Teams": importImages(require.context('../data/galleries/Teams', false, /\.(image|thumbnail)\.png$/)),

  "Individual competition": importImages(require.context('../data/galleries/Individual competition', false, /\.(image|thumbnail)\.png$/)),

  "Team competition": importImages(require.context('../data/galleries/Team competition', false, /\.(image|thumbnail)\.png$/)),

  "Closing ceremony - organizators": importImages(require.context('../data/galleries/Closed ceremony - organizators', false, /\.(image|thumbnail)\.png$/)),

  "Closing ceremony - snapshots": importImages(require.context('../data/galleries/Closed ceremony - snapshots', false, /\.(image|thumbnail)\.png$/)),

  "Winners - individual competition": importImages(require.context('../data/galleries/Winners - individual competition', false,  /\.(image|thumbnail)\.png$/)),

  "Winners - team competition": importImages(require.context('../data/galleries/Winners - team competition', false,  /\.(image|thumbnail)\.png$/)),

}


const GalleryComponent = withStyles(theme => ({
  container: {
    "& > div": {
      outline: 'none',
    }
  },
  gallery: {
    position: 'relative',
    transition: 'all .25s ease-in-out',
    top: '100%',
    transform: 'translateY(-100%)',
    "&.shown": {
      top: '0',
      transform: 'translateY(0)',
    },
    "& .image-gallery-image": {
      //maxHeight: 660,
    },
  },
}))(props => {
  const c = props.classes
  const [ isOpen, setIsOpen ] = useState(false)
  const { getCollapseProps, getToggleProps, mountChildren } = useCollapse({
    isOpen,
    collapsedHeight: window.innerWidth <= 768 ? 56 : 78,
  });

  return (
    <div className={c.container}>
      <div {...getToggleProps({onClick: () => setIsOpen(s => !s), style: {cursor: 'pointer'}, type: undefined})}>
        <Paddinger>
          <Header level={2}>
            { props.name }
          </Header>
        </Paddinger>
      </div>
      <div {...getCollapseProps({onClick: () => setIsOpen(s => true)})}>
        <Gallery className={c.gallery + (mountChildren ? ' shown': "")} items={props.items} lazyLoad/>
      </div>
    </div>
  )
})

const styles = theme => ({
});

function Page(props) {
  let c = props.classes;
  function Measure(image) {
    let img = {
      height: 100,
      width: 100,
    }
    return {
      ...image,
      thumbnailHeight: img.height,
      thumbnailWidth: img.width,
    }
  }
  return (
    <div>
      <Header>
        Galleries
      </Header>
      {
        Object.keys(galleries).map(g => (
          <GalleryComponent key={g} name={g} items={galleries[g]}/>
        ))
      }
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
