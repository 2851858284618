import React, { useState } from 'react';
import withStyles, { keyframes } from 'react-jss'
import uuid from 'uuid';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from 'reactstrap';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Media } from 'react-breakpoints';
import Logo1 from '../data/images/logo2.png';
import Logo2 from '../data/images/logo3.png';
import Logo3 from '../data/images/logo1.png';
import PlusSrc from '../data/images/plus.png';

import Home from './Home.js';
import AboutMEMO from './AboutMEMO.js';
import Schedule from './Schedule.js';
import MEMOGeneralRegulations from './MEMOGeneralRegulations.js';
import PreviousMEMOs from './PreviousMEMOs.js';
import Error from './Error.js';
import Results from './Results.js';
import Problems from './Problems.js';
import Solutions from './Solutions.js';
import Teams from './Teams.js';
import Staff from './Staff.js';
import Rules from './Rules.js';
import Galleries from './Galleries.js';
import LogoTypes from './LogoTypes.js';
import LocalInfo from './LocalInfo.js';

import { Header } from "../components/Text.js";

function importLogos(r) {
  return r.keys().map((item, index) => r(item));
}

const sponsorLogos = importLogos(require.context(`../data/images/sponsors`, false, /\.(png|jpe?g|svg)$/));

let navbarSize = 80;
let animationLen = 45;

const styles = theme => ({
  toggle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  parentDiv: {
    display: "flex",
    flexDirection: "column",
    fontFamily: theme.fontFamily,
    minHeight: "100vh",
  },
  navbar: {
    //position: "absolute",
    background: "white",
    //borderBottom: `${theme.color1} 3px solid`,
  },
  content: {
    marginTop: "112px",
    overflow: "auto",
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
  },
  container: {
    flexGrow: "1",
    position: "relative",
    minHeight: "max-content",
    display: "flex",
    marginTop: "24px",
    marginBottom: "24px",
    padding: "40px",
    zIndex:100,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  footer: {
    marginTop: "25px",
    paddingBottom: "25px",
    paddingTop: "25px",
    backgroundColor: "#EEE",
  },
  logosContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sponsorLogo: {
    margin: "20px",
  },
  brand: {
    position: "relative",
  },
  "@keyframes logoAnimation": {
    "0%": {
      opacity: 1,
    },
    "33.334%": {
      opacity: 0,
    },
  },
  logo: {
    overflow: "hidden",
    height: navbarSize-26+"px",
    width: (navbarSize-26)*7+"px",
    position: "relative",
    "& > img": {
      height: navbarSize-26+"px",
      position: "absolute",
      top: 0,
      left: 0,
      animation: `logoAnimation ${animationLen}s step-end 0s infinite`,
      opacity: 0,
    },
  },
  logo1: {
    animationDelay: "0s!important",
  },
  logo2: {
    animationDelay: animationLen/3 + "s!important",
  },
  logo3: {
    animationDelay: animationLen*2/3 + "s!important",
  },
  dropdownToggle: {
    color: ["#000", "!important"],
  },
  dropdownLink: {
    "&:active": {
      background: [theme.color1],
    },
    "&:focus": {
      outline: "none",
    },
  },
  navLink: {
    color: ["#000", "!important"],
  },
  collapsedNavbar: {
  },
  plus: {
    position: "absolute",
    height: "20px",
    width: "20px",
  },
  plus1: {
    top: 0,
    left: "5px",
  },
  plus2: {
    left: "5px",
    bottom: 0,
  },
  plus3: {
    bottom: 0,
    right: "5px",
  },
  plus4: {
    right: "5px",
    top: 0,
  },
});

function Page(props) {
  let [collapsed, setCollapsed] = useState(true);
  let toggleCollapsed = () => setCollapsed(!collapsed);
  let c = props.classes;

  const NavbarFolder = ({title, children}) => {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className={c.dropdownToggle} nav caret>
          { title }
        </DropdownToggle>
        <DropdownMenu right>
          { children }
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const DropdownLink = ({path, name}) => {
    return (
      <DropdownItem className={c.dropdownLink} onClick={() => {props.history.push(path); setCollapsed(true)}}>
        { name }
      </DropdownItem>
    );
  };

  const Plus = ({className}) => {
    return (
      <img className={`${c.plus} ${className}`} src={PlusSrc}/>
    );
  };

  return (
    <div className={c.parentDiv}>
      <Media>
        { ({currentBreakpoint, breakpoints}) => {
          let shouldCollapse = breakpoints[currentBreakpoint] <= breakpoints.sm;
          return (
            <Navbar className={c.navbar} color="faded" light fixed="top" expand={!shouldCollapse}>
              <Container style={{position: "relative"}} fluid={shouldCollapse}>
                <div className={c.navbarBackground1}/>
                <div className={c.navbarBackground2}/>
                <NavbarBrand href="/" className="mr-auto">
                  <div className={c.brand}>
                    <div className={c.logo}>
                      <img className={c.logo1} src={Logo1}/>
                      <img className={c.logo2} src={Logo2}/>
                      <img className={c.logo3} src={Logo3}/>
                    </div>
                  </div>
                </NavbarBrand>
                <NavbarToggler onClick={toggleCollapsed} className="mr-2" />
                <Collapse isOpen={!collapsed} navbar>
                  <Nav navbar className={`ml-auto ${shouldCollapse ? c.collapsedNavbar : ''}`}>
                    <NavItem>
                      <NavLink className={`nav-link ${c.navLink}`} to={'/'} onClick={()=>setCollapsed(true)}>Home</NavLink>
                    </NavItem>
                    <NavbarFolder title="Info">
                      <DropdownLink path="/schedule" name="Schedule"/>
                      <DropdownLink path="/local-info" name="Local Info"/>
                      {/*<DropdownLink path="/logotypes" name="Logotypes"/>*/}
                      <DropdownLink path="/regulations" name="Regulations"/>
                      <DropdownLink path="/about-memo" name="About MEMO"/>
                      <DropdownLink path="/previous-memos" name="Previous MEMOs"/>
                    </NavbarFolder>
                    <NavbarFolder title="Competition">
                      <DropdownLink path="/problems" name="Problems"/>
                      <DropdownLink path="/solutions" name="Solutions"/>
                      <DropdownLink path="/results" name="Results"/>
                      <DropdownLink path="/rules" name="Rules"/>
                    </NavbarFolder>
                    <NavbarFolder title="People">
                      <DropdownLink path="/teams" name="Teams"/>
                      <DropdownLink path="/staff" name="Staff"/>
                      <DropdownLink path="/galleries" name="Galleries"/>
                    </NavbarFolder>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
          );
        }}
      </Media>
      <div className={c.content}>
        <Container className={c.container}>
          <Plus className={c.plus1}/>
          <Plus className={c.plus2}/>
          <Plus className={c.plus3}/>
          <Plus className={c.plus4}/>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/schedule" component={Schedule}/>
            <Route path="/regulations" component={MEMOGeneralRegulations}/>
            <Route path="/previous-memos" component={PreviousMEMOs}/>
            {/*<Route path="/results" component={Results}/>*/}
            <Route path="/teams" component={Teams}/>
            <Route path="/staff" component={Staff}/>
            <Route path="/rules" component={Rules}/>
            <Route path="/galleries" component={Galleries}/>
            <Route path="/about-memo" component={AboutMEMO}/>
            <Route path="/logotypes" component={LogoTypes}/>
            <Route path="/local-info" component={LocalInfo}/>
            <Route path="/results" component={Results}/>
            <Route path="/problems" component={Problems}/>
            <Route path="/solutions" component={Solutions}/>
            <Route component={Error}/>
          </Switch>
        </Container>
        <div className={c.footer}>
          <Container>
            <Header className={"text-center"} level={2}>
              Organizers and Sponsors
            </Header>
            <div className={c.logosContainer}>
              {
                sponsorLogos.map(logoSrc => (
                  <img className={c.sponsorLogo} key={logoSrc} src={logoSrc}/>
                ))
              }
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default withRouter(withStyles(styles)(Page));
