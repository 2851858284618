import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import ImageGridGallery from 'react-grid-gallery';

export const Gallery = ({children, ...props}) => {
  let Cmp = withStyles(theme => ({
    component: {
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.component;
    
    return (
      <div className={props.className}>
        <ImageGallery {...props}>
          {children}
        </ImageGallery>
      </div>
    );
  }));
  return <Cmp {...props}/>;
};


export const GridGallery = ({children, ...props}) => {
  let Cmp = withStyles(theme => ({
    component: {
    },
  }))(withRouter(({classes, ...props}) => {
    let c = classes;
    if (props.className === undefined) {
      props.className = "";
    }
    props.className += ' ' + c.component;
    
    return (
      <div className={props.className}>
        <GridGallery
          images={props.images}
          enableLightbox
          enableImageSelection={false}/>
      </div>
    );
  }));
  return <Cmp {...props}/>;
};
