import React from 'react';
import withStyles from 'react-jss';
import uuid from 'uuid';
import { NavLink, Link, withRouter, Switch, Route } from "react-router-dom";
import { Header, MyLink, Text } from '../components/Text.js';
import { Paddinger, List, Item } from '../components/Layout.js';

import Individual from '../data/files/solutions/individual.pdf'
import Team from '../data/files/solutions/team.pdf'

const styles = theme => ({
});

function Page(props) {
  const c = props.classes;
  return (
    <div>
      <Header>
        Solutions
      </Header>
      
      <Paddinger>
        <Text>
          <MyLink href={Individual}> Individual Competition </MyLink>
        </Text>
        <Text>
          <MyLink href={Team}> Team Competition </MyLink>
        </Text>
      </Paddinger>
    </div>
  );
}

export default withStyles(styles)(withRouter(Page));
